<template>
    <div class="right_main">
        <!-- 页面标题  按钮部分 -->
        <div class="top_btn">
            <!-- 页面标题部分 -->
            <div class="page_name">
                <div class="sig">
                    <i class="el-icon-arrow-left" @click="handle_goback"></i>
                </div>
                <div class="sig_name">{{title}}</div>
            </div>
            <div class="btn_cl"></div>
        </div>
        <!-- 输入框部分 -->
        <div class="from_main">
            <el-form label-position="left" label-width="118px">
                <el-form-item label="权限名称">
                    <el-input v-model="auth_name" placeholder="请输入权限名称" clearable></el-input>
                    <div class="shugan"></div>
                </el-form-item>
                <el-form-item label="权限列表" class="quan">
                    <div class="routeList-box">
                        <el-tree class="el-tree" :data="routeList" :props="defaultProps" show-checkbox
                            :render-content="renderContent" node-key="id" ref="tree" highlight-current
                            @node-expand="handleExpand" @check-change="handleCheckChange"
                            :default-checked-keys="default_arr" :check-strictly="checkStrictly">
                        </el-tree>
                    </div>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="radio" label="1" @change="stateFn">正常</el-radio>
                    <el-radio v-model="radio" label="2">禁用</el-radio>
                </el-form-item>
            </el-form>
        </div>
        <!-- 提交重置按钮 -->
        <div class="btn_main">
            <el-button class="btn_rest" @click="ResterFn" @change="stateFn">重置</el-button>
            <el-button class="btn_submit" @click="SubmitFn">{{btn_name}}</el-button>
        </div>
    </div>
</template>

<script>
import { showPermissApi, addPermissApi, editPermissApi } from '@/api'
export default {
    data () {
        return {
            // 页面标题
            title: '',
            // 按钮名字
            btn_name: '',
            // 每条数据id
            id: '',
            // 权限名
            auth_name: '',
            radio: '1',
            auth_state: 1,
            routeList: [],  //权限列表
            defaultProps: {
                children: "children",
                label: "auth_name"
            },
            // 树形节点选中数组
            auth_ids: [],
            // 树形控件默认选中数组   去除点父级id的数组
            default_arr: [],
            // 树形控件默认选中数组   没有去除点父级id的数组
            def_arr: [],
            // 所有父级id数组
            getParId: [],
            // 是否严格的遵循父子不互相关联的做法
            checkStrictly: false,
        }
    },
    created () {
        this.changeCss();
    },
    mounted () {
        this.id = this.$route.query.id;
        if (this.id == '添加权限组') {
            this.title = '添加权限组';
            this.btn_name = '确认添加';
            this.add_init();
        } else {
            this.title = '编辑权限组';
            this.btn_name = '确认修改';
            this.edit_init();
        }
    },
    methods: {
        // 添加初始化数据
        add_init () {
            showPermissApi({
                type: 1
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.routeList = res.auth;   //权限列表
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 修改初始化数据
        edit_init () {
            showPermissApi({
                type: 2,
                id: this.id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.routeList = res.auth;   //权限列表
                    let fa_arr = [];
                    let son_arr = [];
                    this.routeList.map(item => {
                        // console.log(item)
                        if (item.children.length == 0) {
                            fa_arr.push(item.id);
                            // console.log(fa_arr)
                        } else {
                            fa_arr.push(item.id);
                            // console.log(fa_arr)
                            item.children.map(child_arr => {
                                son_arr.push(child_arr.id);
                            })
                        }
                    });
                    //存放所有父级id的合集
                    this.getParId = fa_arr.concat(son_arr);
                    // console.log(this.getParId)
                    this.auth_name = res.data.user_name;   //权限名称
                    this.def_arr = res.data.user_auth.split(',').map((value) => { return Number(value); });//权限默认选中
                    for (let i = 0, len = this.def_arr.length; i < len; i++) {
                        if (this.getParId.indexOf(this.def_arr[i]) < 0) {
                            this.default_arr.push(this.def_arr[i]);
                        }
                    };
                    this.auth_state = res.data.auth_status;  //状态
                    if (this.auth_state == 1) {
                        this.radio = '1'
                    } else if (this.auth_state == 2) {
                        this.radio = '2'
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加 编辑，树形控件
        handleExpand () {//节点被展开时触发的事件
            //因为该函数执行在renderContent函数之前，所以得加定时
            setTimeout(() => {
                this.changeCss();
            }, 10);
        },
        renderContent (h, { node, data, store }) {//树节点的内容区的渲染 Function
            let classname = "";
            // 由于项目中有二级菜单也有三级级菜单，就要在此做出判断
            if (node.level === 3) {
                classname = "foo";
            }
            if (node.level === 2 && node.childNodes.length === 0) {
                classname = "foo";
            }
            return h(
                "p",
                {
                    class: classname
                },
                node.label
            );
        },
        changeCss () {
            var levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
            for (var i = 0; i < levelName.length; i++) {
                // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
                levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
                levelName[i].parentNode.style.styleFloat = "left";
                levelName[i].parentNode.onmouseover = function () {
                    this.style.backgroundColor = "#fff";
                };
            }
        },
        // 节点选中状态发生变化时的回调
        handleCheckChange () {
            let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
            this.auth_ids = res.join(',');
        },
        // 状态单选
        stateFn () {
            if (this.radio == '1') {
                this.auth_state = 1;
            } else {
                this.auth_state = 2;
            }
        },
        // 点击重置按钮
        ResterFn () {
            this.auth_name = '';     //权限名
            this.radio = '1';     //状态
            this.$refs.tree.setCheckedKeys([]);   // 权限列表
            this.getParId = [];
            this.default_arr = [];
        },
        // 点击返回按钮返回
        handle_goback () {
            this.$router.push({ path: '/authgroup' })
        },
        // 点击提交按钮
        SubmitFn () {
            if (this.id == '添加权限组') {
                addPermissApi({
                    user_name: this.auth_name,  //权限名称
                    user_auth: this.auth_ids,    //权限列表
                    auth_status: this.auth_state,   //状态
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.$router.push({ path: '/authgroup' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else {
                if (this.auth_ids.length == 0) {
                    this.auth_ids = this.def_arr.join(',')
                };
                editPermissApi({
                    id: this.id,
                    user_name: this.auth_name,  //权限名称
                    user_auth: this.auth_ids,    //权限列表
                    auth_status: this.auth_state,   //状态
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.$router.push({ path: '/authgroup' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    //  页面标题  按钮部分
    .top_btn {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 24px;
        border-bottom: 1px solid #e8e9f1;
        // 页面标题部分
        .page_name {
            display: flex;
            align-items: center;
            .sig {
                margin-right: 6px;
                i {
                    font-size: 24px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            .sig_name {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
        }
        .btn_cl {
            width: 10px;
        }
    }
    // 输入框部分
    .from_main {
        padding: 24px;
        .el-form {
            text-align: left;
            .el-form-item {
                width: 370px;
                position: relative;
                border: 1px solid #d9d9d9;
                border-radius: 6px;
                /deep/.el-form-item__label {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                }
                .el-input {
                    border: none;
                }
                /deep/.el-input__inner {
                    border: none;
                }
                .shugan {
                    background: #d3d3d6;
                    width: 2px;
                    height: 16px;
                    position: absolute;
                    right: 258px;
                    top: 12px;
                }
            }
            .quan {
                border: none;
            }
            // 添加  编辑
            .routeList-box {
                margin-bottom: 20px;
                .el-collapse {
                    border: none;
                }
                .el-tree {
                    margin: 12px 0;
                }
                /deep/.el-tree-node__content {
                    padding-left: 0px;
                }
                /deep/.el-collapse-item__header {
                    height: 40px;
                    background-color: #f4f4f4;
                    padding: 0 10px;
                }
                /deep/.el-icon-arrow-right:before {
                    color: #409eff;
                }
            }
            /deep/ .el-tree-node__content::before {
                content: "";
                padding-left: 10px;
            }
            /deep/.el-checkbox__input {
                margin-right: 6px;
            }
        }
    }
    // 提交 重置按钮部分
    .btn_main {
        margin-top: 100px;
        text-align: left;
        padding: 0 20px 32px;
        .el-button {
            width: 160px;
            height: 40px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
        .btn_submit {
            border: none;
            background: #6a8be8;
            margin-left: 8px;
            color: #ffffff;
        }
        .btn_rest {
            border: 1px solid #d9d9d9;
            color: #20252b;
            background: #ffffff;
        }
    }
}
</style>